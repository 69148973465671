import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { message, Upload } from 'antd';
import { find } from 'lodash';

import Notification from '../../../components/notifications/notification';

import infoIcon from '../../../assets/icons/info-icon-1.svg';
import uploadIcon from '../../../assets/icons/upload-primary-icon.svg';
import uploadIconSp from '../../../assets/icons/upload-icon-primary-sp.svg';
import fileIconSp from '../../../assets/icons/document-text-sp.svg';
import fileIcon from '../../../assets/icons/document-text.svg';
import folderViewIcon from '../../../assets/icons/folderView.svg';
import downloadIcon from '../../../assets/icons/download.svg';
import folderViewIconSp from '../../../assets/icons/folderViewSp.svg';
import downloadIconSp from '../../../assets/icons/downloadSp.svg';

import { UploadDocumentsWrapper } from '../style';

import { APP_DOMAINS } from '../../../constants';
import { DownloadImageFromUrl, GetS3ImageUrl } from '../../../helpers/get-s3-image-url';

import { GetPreSignedUrl } from '../../../redux/slices/shipment-slice';

const { Dragger } = Upload;

const ProofOfDelivery = ({ uploaded, files = [], shipmentId }) => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [deliveryFiles, setDeliveryFiles] = useState([]);
  const {
    loading,
    error,
    success
  } = useSelector((state) => state.shipment);

  const props = {
    name: 'file',
    multiple: true,
    accept: 'image/jpeg,image/png,image/jpg,application/pdf',
    onChange(info) {
      console.log({ ...info })
      setUploading(true);
      dispatch(GetPreSignedUrl({
        files: info.fileList,
        module: 'Shipment',
        category: 'POD',
        caseType: 'Shipment'
      }));
    },
    onDrop() {
    },
    beforeUpload(file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        message.error('You can only upload JPG, JPEG, PNG, or PDF files!');
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    fileList: []
  };

  useEffect(() => {
    if (files.length) {
      const deliveryFilesData = files.filter((file) => file.shipmentDocumentType === 'POD' && file.processedShipmentId === shipmentId);
      setDeliveryFiles(deliveryFilesData)
    }
  }, [files]);

  useEffect(() => {
    console.log('condition:', (!loading && !error && success && uploading), 'In here');
    if (!loading && !error && success && uploading) {
      Notification({
        type: 'success',
        title: 'File Uploaded Successfully',
        description: 'Make sure to Submit Documents'
      })
      setUploading(false);
    }
  }, [uploading, loading])

  useEffect(() => {
    if (uploaded.length) {
      const prevFiles = deliveryFiles;
      console.log('previous:', prevFiles)
      const newFiles = uploaded?.filter((file) => file.category === 'POD' && !find(prevFiles, { s3Url: file.s3Url }))
      console.log('new:', newFiles)
      prevFiles.push(...newFiles);
      console.log('new final:', prevFiles)
      setDeliveryFiles(prevFiles)
    }
  }, [uploaded]);

  return (
    <UploadDocumentsWrapper>
      <div className='ownership-box'>
        <div className='ownership-heading'>
          <span>Proof of Delivery</span>
        </div>
        <div className='inner-box'>
          <div className='inventory-box'>
            <div className='d-flex align-items-center info-title'>
              <img src={infoIcon} alt='info' />
              <span>There are essentially three types of documents that suffice for Proof of Delivery:</span>
            </div>
            <Row noGutters className='equal-row-height'>
              <Col sm={4}>
                <div className='slip-box'>
                  <span className='slip-title'>BOL with Amazon Stamp (LTL or FTL shipments)</span>
                  <ul>
                    <li>Must be stamped by Amazon confirming that the shipment was received and signed for at the fulfillment center.</li>
                    <li>Actual number of boxes shipped and total weight from the shipping plan should match.</li>
                    <li>Note: If not, Amazon might not reimburse the missing units.</li>
                  </ul>
                </div>
              </Col>
              <Col sm={4} className='pl-3'>
                <div className='slip-box'>
                  <span className='slip-title'>Proof of Delivery (POD) (small-parcel shipments)</span>
                  <ul>
                    <li>Tracking Number: Tracking numbers should show items were shipped and delivered.</li>
                    <li>Total Boxes Shipped</li>
                    <li>Ship from and Ship to address</li>
                    <li>Note: All information should match on the shipment.</li>
                  </ul>
                </div>
              </Col>
              <Col sm={4} className='pl-3'>
                <div className='slip-box'>
                  <span className='slip-title'>Electronic POD Instructions (If using another carrier)</span>
                  <ul>
                    <li>Search in Carrier Central for Electronic P.O.D with corresponding ISA.</li>
                    <li>Click <span>'Search By Appointment IDs'</span> Tab and click 'Submit'.</li>
                    <li>Select <span>'View'</span> and the Electronic P.O.D will populate.</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className='upload-document'>
            <Row>
              <Col sm={6}>
                <div>
                  <span className='upload-heading'>Upload Document</span>
                  <Dragger {...props}>
                    <div className='d-flex align-items-center justify-content-center drag-files'>
                      <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? uploadIcon : uploadIconSp} alt='uplaod' />
                      <div>
                        <p>Drag and Upload your files</p>
                        <p>JPG, JPEG, PNG, PDF</p>
                      </div>
                    </div>
                  </Dragger>
                </div>
              </Col>
              <Col sm={6}>
                {deliveryFiles.length === 0 ?
                  <div className='no-file-box'>
                    <span>File is not Uploaded!</span>
                  </div>
                  :
                  <div>
                    <span className='upload-heading'>Uploaded File’s</span>
                    <div className='uploaded-height'>
                      {deliveryFiles.map((item, ind) => (
                        <div key={ind} className='d-flex align-items-center justify-content-between uploaded-files'>
                          <div className='d-flex align-items-center'>
                            <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? fileIcon : fileIconSp} alt='file' />
                            <span>{item.name}</span>
                          </div>
                          <div className='d-flex align-items-center'>
                            <img
                              className='cursor-pointer'
                              src={window.location.hostname === APP_DOMAINS.sellerTerminal ? folderViewIcon : folderViewIconSp}
                              alt='folder'
                              onClick={() => window.open(GetS3ImageUrl({ bucketName: process.env.ST_SHIPMENT_BUCKET, key: item.key }), '_blank')}
                            />
                            <img
                              className='cursor-pointer'
                              src={window.location.hostname === APP_DOMAINS.sellerTerminal ? downloadIcon : downloadIconSp}
                              alt='download'
                              onClick={() => DownloadImageFromUrl(GetS3ImageUrl({ bucketName: process.env.ST_SHIPMENT_BUCKET, key: item.key }), item.name)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </UploadDocumentsWrapper>
  )
}

export default ProofOfDelivery;
