import React, { useEffect, useLayoutEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import crypto from 'crypto';

import { Alert as InfoAlert } from 'antd';

import Alert from "../../../components/alert";
import Button from "../../../components/ButtonSt";
import ProgressSt from "../../../components/ProgressSt";
import TableSt from "../../../components/TableSt";
import PotentialRecoveryCard from "../../../components/PotentialRecoveryCard";
import Eligible from "./eligible";
import NoEligible from "../StepOne/noEligible";
import Notification from "../../../components/notifications/notification";

import { 
  APP_DOMAINS,
  APP_IDS,
  BASE_URLS,
  REDIRECT_URIS
} from "../../../constants";

import {
  SocketConnection,
  DisconnectSocket,
  HandleStatusUpdate,
  HandleInviteUpdate,
  HandleRecoveryUpdate
} from "../../../helpers/socket";

import SearchIcon from "../../../assets/images/exploration-icon.svg";
import CopyIcon from "../../../assets/images/copy-icon.svg";
import CustomerIcon from "../../../assets/images/accounting-icon.svg";
import Permission from "../../../assets/images/permission-2.svg";
import PermissionSp from "../../../assets/images/sp-key-icon.svg";
import CheckIcon from "../../../assets/images/check-icon-st.svg";
import FailedIcon from "../../../assets/images/failed-icon.svg";

import { StepTwoWrapper } from "./style";

import {
  CreateNewRefreshToken,
  GetPotentialRecovery,
  GetProgress,
  GetStore,
  SetStoreState
} from "../../../redux/slices/store-slice";
import { GetLatestAmazonAccountDetail, SetState } from "../../../redux/slices/account-detail-slice";
import { SetAuthState } from "../../../redux/slices/auth-slice";

const StepTwo = ({ currentStep, setStep }) => {
  const hostName = window.location.hostname;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    store,
    storeId,
    error,
    state,
    progress,
    potentialRecovery,
    success,
    recoveredAmount,
    newTokenSuccess
  } = useSelector((state) => state.store);
  const { 
    latestAmazonAccountDetail,
    accountId,
    amazonAccountSuccess,
    managerName
  } = useSelector((state) => state.accountDetail);
  const { user: { userId } } = useSelector((state) => state.auth);

  const [recheck, setRecheck] = useState(false);
  const [noteligible, setNoteligible] = useState(false);
  const [eligible, setEligible] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(null);
  const [progressInterval, setProgressInterval] = useState('');
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const copyToClipboard = (text) => {
    setOpen(true);
    return navigator.clipboard
      .writeText(text)
      .then(t => true)
      .catch(e => false);
  };

  const dataSource = [
    {
      key: "1",
      name: (
        <div className="card_ui_wrapper">
          <p>
            {
              loader ? (
                hostName === APP_DOMAINS.sellerRepay ?
                  managerName || 'Seller Repay Manager' :
                  managerName || 'Seller Terminal Manager'
              ) : (
                <Spin size="small" />
              )
            }
          </p>
          <img onClick={() => copyToClipboard(hostName === APP_DOMAINS.sellerRepay ? managerName || 'Seller Repay Manager' : managerName || 'Seller Terminal Manager')} src={CopyIcon} />
        </div>
      ),
      email: (
        <div className="card_ui_wrapper">
          <p>{latestAmazonAccountDetail}</p>
          <img onClick={() => copyToClipboard(latestAmazonAccountDetail)} src={CopyIcon} />
        </div>
      ),
      language: (
        <div className="overlay_card_item">
          <div className="card_ui_wrapper">
            <p>
              English <FaChevronDown />
            </p>
          </div>
          <p className="card_ui_item"> Invite</p>
        </div>
      ),
    },
  ];

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Language", dataIndex: "language", key: "language" },
  ];

  const getProgressProcessStart = () => {
    dispatch(GetStore({ storeId }));
    dispatch(GetProgress({ storeId }));
  }

  useEffect(() => {
    dispatch(GetStore({ storeId }));
    if (!store?.invite?.amazonAccountDetailId) {
      dispatch(GetLatestAmazonAccountDetail());
    }
    dispatch(GetProgress({ storeId }));
    let interval;

    if (progress !== 100) {
      interval = setInterval(getProgressProcessStart, 30 * 1000);
      setProgressInterval(interval);
    }

    window.addEventListener('beforeunload', () => {
      DisconnectSocket(socket);
      clearInterval(progressInterval);
    });

    return () => {
      if (interval) {
        clearInterval(interval);
      }

      window.removeEventListener('beforeunload', () => {
        DisconnectSocket(socket);
        clearInterval(progressInterval);
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (progress === 100) {
      dispatch(GetPotentialRecovery({ storeId }));
      if (progressInterval) {
        clearInterval(progressInterval);
      }
    }
  }, [progress])

  useEffect(() => {
    if (progress === 100 && potentialRecovery !== null) {
      if (recoveredAmount < 250 && potentialRecovery < 200) {
        setNoteligible(true);
      } else {
        dispatch(GetStore({ storeId }));
      }
    }
  }, [potentialRecovery]);

  useEffect(() => {
    const socket = SocketConnection({ url: process.env.SERVER_URL, userId });

    HandleStatusUpdate(socket, dispatch, storeId, store);
    HandleInviteUpdate(socket, dispatch, storeId);
    HandleRecoveryUpdate(socket, dispatch, storeId, potentialRecovery);

    return () => {
      DisconnectSocket(socket);
    }
  }, [])

  useEffect(() => {
    if (store?.status === 'Invite_Accepted' && progress !== 100) {
      setEligible(true);
    }

    if (!store?.spApi?.apiConnected && !apiLoading) {
      const params = new URLSearchParams(window.location?.search);
      const authCode = params.get('spapi_oauth_code');
      const sellerId = params.get('selling_partner_id');
      const spApiState = params.get('state');

      if (location.pathname === '/auth/sp-api' && authCode && sellerId && spApiState) {
        setApiLoading(true);
        console.log({
          authCode,
          sellerId,
          spApiState,
          state
        });

        if (sellerId && spApiState === state) {
          dispatch(CreateNewRefreshToken({
            authCode: authCode,
            sellerId: sellerId
          }));
        } else {
          dispatch(SetStoreState({ field: 'error', value: 'Error in store creation' }))
        }
      }
    }
  }, [store])

  useEffect(() => {
    if (newTokenSuccess !== null) {
      setApiLoading(false);
      setNoteligible(false);
      dispatch(GetProgress({ storeId }));
      dispatch(SetStoreState({ field:'newTokenSuccess', value: null }));
      dispatch(SetAuthState({ field: 'storeStatus', value: store.status }));
      dispatch(SetStoreState({ field: 'state', value: '' }));
    }
  }, [newTokenSuccess]);

  useEffect(() => {
    if (isButtonClicked && !loading) {
      setIsButtonClicked(false);
    }
  }, [loading])

  const checkInvite = async () => {
    dispatch(GetStore({ storeId, checkInvite: true }));
    setIsButtonClicked(true);
    setLoading(true);
    setRecheck(true);
  }

  useEffect(() => {
    if(isButtonClicked !== null) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [success, error])

  useEffect(() => {
    if (amazonAccountSuccess !== null) {
      setLoader(true);
      dispatch(SetState({ field: 'amazonAccountSuccess', value: null }));
    }
  }, [amazonAccountSuccess]);

  useEffect(() => {
    if (open) {
      Notification({
        type: "success",
        title: "Copied !!!"
      });
    }
    setOpen(false);
  }, [open]);

  const handleStoreCompleteion = () => {
    if (!store?.spapi?.apiConnected) {
      const state = crypto.randomBytes(20).toString("hex");

      dispatch(SetStoreState({ field: 'state', value: state }));

      const url = `https://sellercentral-europe.amazon.com/authorization/select-account?returnTo=/apps/authorize/consent?state=${state}%26application_id=${APP_IDS[hostName]}&redirect_uri=${REDIRECT_URIS[hostName]}`
      window.open(url, "_self");
    }
  }

  return (
    <>
      <InfoAlert
        message="Information" 
        description="Due to a technical issue, we are unable to process any new accounts. We will contact you as/when the situation changes" 
        type="info" 
        showIcon
        style={{
          backgroundColor: '#e6f7ff',  
          border: '1px solid #91d5ff',
          color: '#000',  
          fontSize: '16px', 
          borderRadius: '6px',
          padding: '20px',
          marginBottom: '15px'
        }}
      />
      {
        (!store?.spApi?.apiConnected && !apiLoading) ? (
          <Alert
            buttonText={`Click Here`}
            endText={'restore your access.'}
            errorText={`For "${store?.name}" we are unable to fetch your Seller Central Data. `}
            resolve={() => handleStoreCompleteion()}
            marginBottom='3px'
          />
        ): null
      }
      {apiLoading ? (
        <div className="d-flex justify-content-center align-content-center align-items-center">
          <Spin size="large"/>
        </div>
      ):
      noteligible ? (
        <NoEligible setStep={setStep} />
      ) : (
        (eligible && progress !== 100) ? (
          <Eligible progress={progress} />
        ) : (
          <StepTwoWrapper>
            <>
              {
                (progress === 100 && (potentialRecovery > 0 || recoveredAmount > 0)) ? (
                  <PotentialRecoveryCard />
                ) : (
                  <div className="card_wrapper">
                    <div className="card_overlay_wrapper">
                      <img src={SearchIcon} alt="SearchIcon" />
                      <div className="card_overlay_item">
                        <h3>We are analyzing your data</h3>
                        <p>Processing reimbursement data for your account. </p>
                      </div>
                      <ProgressSt percent={progress || 0} />
                    </div>
                  </div>
                )
              }

              <div className="overlay_wrapper">
                <div className="header_wrapper">
                  <img src={CustomerIcon} />
                  <h2>Invite your case manager</h2>
                </div>
                <div className="card-items_wrapper">
                  <div className="card_items_overlay">
                    <div className="card_items">
                      <Button onClick={() => window.open(`${BASE_URLS[store.region]}gp/account-manager/home.html/ref=xx_userperms_dnav_xx`, '_blank')} outline>Invite User</Button>
                      <p>
                        Sign into your Seller Central Account and navigate to{" "}
                        <b>'Settings'</b> Tab, then click <b>'User Permissions' </b>
                      </p>
                    </div>
                    <div className="card_items_content-overlay">
                      <p>
                        Enter the Information below, then click the <b>"Invite"</b>{" "}
                        button:
                      </p>
                      <div className="table_container">
                        <h6>Add a New User</h6>
                        <TableSt
                          className="step_two_table_wrapper"
                          dataSource={dataSource}
                          pagination={false}
                          columns={columns}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="right_side_content_overlay">
                    {
                      (loading && isButtonClicked) ? (
                        <Spin size="medium" />
                      ) : (
                        (!store?.invite?.inviteUrl && !recheck) ? (
                          <div className="right_side_content_wrapper">
                            <img src={hostName === APP_DOMAINS.sellerTerminal ? Permission : PermissionSp} />
                            <h4>Invite</h4>
                            <p>
                              Please confirm once your invite's been sent by clicking below.
                            </p>
                            <Button outline onClick={checkInvite}>Check Invite</Button>
                          </div>
                        ) : (
                              (store?.invite?.inviteUrl && store?.invite?.amazonAccountDetailId === accountId)  ? (
                            <div className="right_side_content_wrapper">
                              <img src={CheckIcon} />
                              <h4>Invite successfully received!</h4>
                              <p>
                                Great news - We've received your invitation. You will shortly be receiving an email confirming the
                                next steps.
                              </p>
                            </div>
                          ) : (
                            <div className="right_side_content_wrapper">
                              <img src={FailedIcon} />
                              <h4>Invite not received yet!</h4>
                              <p>
                                We are unable to process your claims until this step in complete. Please check you have followed the instructions correctly, then re-click below.
                              </p>
                              <Button outline onClick={checkInvite}>Recheck Invite</Button>
                            </div>
                          )
                        )
                      )}
                  </div>
                </div>
              </div>
            </>
          </StepTwoWrapper>
        )
      )}
    </>
  );
};

export default StepTwo;
