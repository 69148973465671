import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { message, Upload } from 'antd';
import { find } from 'lodash';

import Notification from '../../../components/notifications/notification';

import infoIcon from '../../../assets/icons/info-icon-1.svg';
import uploadIcon from '../../../assets/icons/upload-primary-icon.svg';
import uploadIconSp from '../../../assets/icons/upload-icon-primary-sp.svg';
import fileIconSp from '../../../assets/icons/document-text-sp.svg';
import fileIcon from '../../../assets/icons/document-text.svg';
import folderViewIcon from '../../../assets/icons/folderView.svg';
import downloadIcon from '../../../assets/icons/download.svg';
import folderViewIconSp from '../../../assets/icons/folderViewSp.svg';
import downloadIconSp from '../../../assets/icons/downloadSp.svg';

import { UploadDocumentsWrapper } from '../style';

import { APP_DOMAINS } from '../../../constants';
import { DownloadImageFromUrl, GetS3ImageUrl } from '../../../helpers/get-s3-image-url';

import { GetPreSignedUrl } from '../../../redux/slices/shipment-slice';

const { Dragger } = Upload;

const InventoryOwnership = ({ uploaded = [], files = [], shipmentId }) => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [ownershipFiles, setOwnershipFiles] = useState([]);
  const {
    loading,
    error,
    success
  } = useSelector((state) => state.shipment);
  const props = {
    name: 'file',
    multiple: true,
    accept: 'image/jpeg,image/png,image/jpg,application/pdf',
    onChange(info) {
      setUploading(true);
      dispatch(GetPreSignedUrl({
        files: info.fileList,
        module: 'Shipment',
        category: 'POO',
        caseType: 'Shipment'
      }));
    },
    onDrop() {
    },
    beforeUpload(file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        message.error('You can only upload JPG, JPEG, PNG, or PDF files!');
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    fileList: []
  };

  useEffect(() => {
    if (files.length) {
      const ownershipFilesData = files.filter((file) => file.shipmentDocumentType === 'POO' && file.processedShipmentId === shipmentId);
      setOwnershipFiles(ownershipFilesData)
    }
  }, [files]);

  useEffect(() => {
    if (!loading && !error && success && uploading) {
      Notification({
        type: 'success',
        title: 'File Uploaded Successfully',
        description: 'Make sure to Submit Documents'
      })
      setUploading(false);
    }
  }, [uploading, loading])

  useEffect(() => {
    if (uploaded.length) {
      const prevFiles = ownershipFiles;
      const newFiles = uploaded?.filter((file) => file.category === 'POO' && !find(prevFiles, { s3Url: file.s3Url }))
      prevFiles.push(...newFiles);
      setOwnershipFiles(prevFiles)
    }
  }, [uploaded]);

  return (
    <UploadDocumentsWrapper>
      <div className='ownership-box'>
        <div className='ownership-heading'>
          <span>Proof of Inventory Ownership</span>
        </div>
        <div className='inner-box'>
          <div className='inventory-box'>
            <div className='d-flex align-items-center info-title'>
              <img src={infoIcon} alt='info' />
              <span>This will help identify any discrepancies and serve as proof of available inventory for Amazon.
                The document, whether a Packing List for Manufacturer or Invoice/Receipt for Reseller, must contain the following information:</span>
            </div>
            <Row noGutters className='equal-height-row'>
              <Col sm={6}>
                <div className='slip-box'>
                  <span className='slip-title'>PACKING SLIP - MANUFACTURER</span>
                  <ul>
                    <li><span>Product Name -</span> Should show the product w/ missing units.</li>
                    <li><span>SHIP FROM: -</span> Should be a US warehouse address where it was shipped from</li>
                    <li><span>SHIP TO: -</span> Should be the Amazon Address where it was shipped to</li>
                    <li><span>Quantity -</span> Should show the product w/ missing units. </li>
                    <li><span>Shipment Date</span> Should show the product w/ missing units. </li>
                    <li><span>Manufacturer's Signature or Stamp</span></li>
                  </ul>
                </div>
              </Col>
              <Col sm={6} className='pl-3'>
                <div className='slip-box'>
                  <span className='slip-title'>INVOICE - RESELLER</span>
                  <ul>
                    <li><span>Product Name -</span> Should show the product w/ missing units.</li>
                    <li><span>Quantity</span> Invoice should have equal to or more than what was shipped</li>
                    <li><span>Date of Purchase </span> Must be within six months of shipped/created date</li>
                    <li><span>Supplier -</span> Should show the supplier name, address and contact info</li>
                    <li><span>Purchaser -</span> Should show the name and address or contact info of the purchaser.</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className='upload-document'>
            <Row>
              <Col sm={6}>
                <div>
                  <span className='upload-heading'>Upload Document</span>
                  <Dragger {...props}>
                    <div className='d-flex align-items-center justify-content-center drag-files'>
                      <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? uploadIcon : uploadIconSp} alt='uplaod' />
                      <div>
                        <p>Drag and Upload your files</p>
                        <p>JPG, JPEG, PNG, PDF</p>
                      </div>
                    </div>
                  </Dragger>
                </div>
              </Col>
              <Col sm={6}>
                {ownershipFiles.length === 0 ?
                  <div className='no-file-box'>
                    <span>File is not Uploaded!</span>
                  </div>
                  :
                  <div>
                    <span className='upload-heading'>Uploaded File’s</span>
                    <div className='uploaded-height'>
                      {ownershipFiles.map((item, ind) => (
                        <div key={ind} className='d-flex align-items-center justify-content-between uploaded-files'>
                          <div className='d-flex align-items-center'>
                            <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? fileIcon : fileIconSp} alt='file' />
                            <span>{item.name}</span>
                          </div>
                          <div className='d-flex align-items-center'>
                            <img 
                              className='cursor-pointer'
                              onClick={() => window.open(GetS3ImageUrl({ bucketName: process.env.ST_SHIPMENT_BUCKET, key: item.key }), '_blank')} 
                              src={window.location.hostname === APP_DOMAINS.sellerTerminal ? folderViewIcon : folderViewIconSp}
                              alt='folder' 
                            />
                            <img 
                              className='cursor-pointer'
                              src={window.location.hostname === APP_DOMAINS.sellerTerminal ? downloadIcon : downloadIconSp}
                              onClick={() => DownloadImageFromUrl(GetS3ImageUrl({ bucketName: process.env.ST_SHIPMENT_BUCKET, key: item.key }), item.name)} 
                              alt='download' 
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </UploadDocumentsWrapper>
  )
}

export default InventoryOwnership;
